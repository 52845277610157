import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7d27bffc = () => interopDefault(import('../pages/adasok/index.vue' /* webpackChunkName: "pages/adasok/index" */))
const _c5045e48 = () => interopDefault(import('../pages/kereses.vue' /* webpackChunkName: "pages/kereses" */))
const _c297f548 = () => interopDefault(import('../pages/munkatarsak.vue' /* webpackChunkName: "pages/munkatarsak" */))
const _03c7fff5 = () => interopDefault(import('../pages/musorok/index.vue' /* webpackChunkName: "pages/musorok/index" */))
const _247e9d5a = () => interopDefault(import('../pages/musorvezetok/index.vue' /* webpackChunkName: "pages/musorvezetok/index" */))
const _15e6b2e9 = () => interopDefault(import('../pages/player.vue' /* webpackChunkName: "pages/player" */))
const _b0d0054c = () => interopDefault(import('../pages/playlist.vue' /* webpackChunkName: "pages/playlist" */))
const _64f24a9e = () => interopDefault(import('../pages/adasok/_adas.vue' /* webpackChunkName: "pages/adasok/_adas" */))
const _14e248dc = () => interopDefault(import('../pages/musorok/_musor.vue' /* webpackChunkName: "pages/musorok/_musor" */))
const _e5698b5a = () => interopDefault(import('../pages/musorvezetok/_musorvezeto.vue' /* webpackChunkName: "pages/musorvezetok/_musorvezeto" */))
const _4859e67a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _46a34532 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adasok",
    component: _7d27bffc,
    name: "adasok"
  }, {
    path: "/kereses",
    component: _c5045e48,
    name: "kereses"
  }, {
    path: "/munkatarsak",
    component: _c297f548,
    name: "munkatarsak"
  }, {
    path: "/musorok",
    component: _03c7fff5,
    name: "musorok"
  }, {
    path: "/musorvezetok",
    component: _247e9d5a,
    name: "musorvezetok"
  }, {
    path: "/player",
    component: _15e6b2e9,
    name: "player"
  }, {
    path: "/playlist",
    component: _b0d0054c,
    name: "playlist"
  }, {
    path: "/adasok/:adas",
    component: _64f24a9e,
    name: "adasok-adas"
  }, {
    path: "/musorok/:musor",
    component: _14e248dc,
    name: "musorok-musor"
  }, {
    path: "/musorvezetok/:musorvezeto",
    component: _e5698b5a,
    name: "musorvezetok-musorvezeto"
  }, {
    path: "/",
    component: _4859e67a,
    name: "index"
  }, {
    path: "/:slug",
    component: _46a34532,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
